<template>
    <div >
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar  dense color="white" class="tc-title">
                <router-link to="student-academics" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
                <v-toolbar-title>Internship & Project Details</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="logi mx-2" fab small @click="dialog_add=true"><v-icon>mdi-plus</v-icon></v-btn>
                <router-link to="student-skill-certification" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
        </v-toolbar>
        <!--<template>
            <v-toolbar flat dense color="white">
                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                    </v-text-field>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="logi" @click="dialog_add=true"><v-icon>mdi-plus</v-icon> Add Details</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
            <v-data-table :headers="headers" :items="project_List" class="elevation-1" :search="search">
                <template v-slot:item.edit="{ item }">
                    <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                </template>
                <template v-slot:item.delete="{ item }">
                    <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                </template>
            </v-data-table>
        </template>-->
        <v-row>
            <v-col cols="12" sm='4' v-for="item in project_List" :key="item">
                <v-card style=" margin: 8px; height:100%">
                    <v-card-title class="c-title">
                        {{item.title}}
                    </v-card-title>
                    <v-card-text>
                        <div style="padding: 0px"></div>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important" align="left">
                                <span class="lbl" align="left">Domain</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important" align="left">
                                : {{item.domain}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Description</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important">
                                : {{item.description}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Technology</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important">
                                : {{item.technology_used}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important" >
                                <span class="lbl">Role</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important">
                                : {{item.role}}
                            </v-col>
                        </v-row>
                        <!-- <v-row>
                            <v-col cols="6" sm="4">
                                <span class="lbl">Project Type</span>
                            </v-col>
                            <v-col cols="6" md="6">
                                : {{item.projectcategory}}
                            </v-col>
                        </v-row> -->
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Project Category</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important">
                                : {{item.projectcategory}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Organization</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important">
                                : {{item.organization_name}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Location</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important">
                                : {{item.location}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Start Date</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important">
                                : {{item.start_date_show}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important" align="left">
                                <span class="lbl">End Date</span>
                            </v-col>
                            <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important">
                                : {{item.end_date_show}}
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                        <v-spacer></v-spacer>
                        <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>

                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add" persistent max-width="750px">
                    <v-card>
                        <v-card-title class="card-title">
                            Add Internship & Project Details
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Project Title" label=" Project Title :" v-model="savedata.title" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.savedata.title ? 'Please enter title' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Domain" label="Domain" v-model="savedata.domain" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.savedata.domain ? 'Please enter domain' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Description" label="Description :" v-model="savedata.description" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode == 46 || event.keyCode == 44 || event.charCode == 32 "
                      :error-messages="error.savedata.description ? 'Please enter description' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Role" label="Role :" v-model="savedata.role" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.savedata.role ? 'Please enter role' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Technology Used" label="Technology Used :" v-model="savedata.technology" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode == 46  || event.keyCode == 44  ||event.charCode == 32 "
                      :error-messages="error.savedata.technology ? 'Please enter technology' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="pro_cat_list" label="Project Category :" v-model="savedata.project_cat" item-text="proj_cat" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.savedata.project_cat ? 'Please select project category' : ''"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                    <!-- <v-text-field placeholder="Enter Internal Guide" label="Internal Guide :" v-model="savedata.intername_guide" :rules="[v => !!v || 'required']"></v-text-field> -->
                                        <!-- <v-select :items="internal_guide_list" label="Internal Guide :" v-model="savedata.intername_guide" item-text="employee_code" item-value="id" required :rules="[v => !!v || 'required']"></v-select> -->
                                        <v-combobox
                                            class="text"
                                            v-model="savedata.intername_guide"
                                            :items="internal_guide_list"
                                            item-text="employee_code"
                                            item-value="id"
                                            placeholder="Internal Guide"
                                            outlined
                                            dense
                                            clearable
                                            require
                                            :error-messages="error.savedata.intername_guide ? 'Please select internal guide' : ''"
                                        ></v-combobox>
                                    
                                    
                                    
                                    
                                    
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter External Guide" label="External Guide :" v-model="savedata.external_guide" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.savedata.external_guide ? 'Please enter external guide' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Organization Name" label="Organization :" v-model="savedata.org" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode > 47 && event.charCode < 58) || event.charCode == 32 "
                      :error-messages="error.savedata.org ? 'Please enter organization' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Location" label="Location :" v-model="savedata.location" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.savedata.location ? 'Please enter location' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" :rules="[v => !!v || 'required']">
                                        Start Date :<br />
                                        <v-text-field type="date" placeholder="Start Date" v-model="savedata.start_date" 
                                        :error-messages="error.savedata.start_date ? 'Please select start date' : ''"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" :rules="[v => !!v || 'required']">
                                        End Date :<br />
                                        <v-text-field type="date" placeholder="End Date" v-model="savedata.end_date"  
                                        :error-messages="error.savedata.end_date ? 'Please select end date' : ''"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_add= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Save</v-btn> -->

                            <v-btn color="primary darken-1"  outlined text @click="dialog_add= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_edit" persistent max-width="750px">
                    <v-card>
                        <v-card-title class="card-title">
                            Edit Internship & Project Details
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Project Title :" label=" Project Title" v-model="editedItem.title" :rules="[v => !!v || 'required']"
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.editedItem.title ? 'Please enter title' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Domain " label="Domain :" v-model="editedItem.domain" :rules="[v => !!v || 'required']"
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.editedItem.domain ? 'Please enter domain' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Description" label="Description :" v-model="editedItem.description" :rules="[v => !!v || 'required']"
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode == 46 || event.keyCode == 44 || event.charCode == 32 "
                      :error-messages="error.editedItem.description ? 'Please enter description' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Role" label="Role :" v-model="editedItem.role" :rules="[v => !!v || 'required']"
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.editedItem.role ? 'Please enter role' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Technology Used" label="Technology Used :" v-model="editedItem.technology" :rules="[v => !!v || 'required']"
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode == 46  || event.keyCode == 44  ||event.charCode == 32 "
                      :error-messages="error.editedItem.technology ? 'Please enter technology' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="pro_cat_list" label="Project Category :" v-model="editedItem.project_cat" item-text="proj_cat" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.editedItem.project_cat ? 'Please select project category' : ''"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <!--- <v-select
                                :items="internal_guide_list"
                                label="Internal Guide :"
                                v-model="editedItem.intername_guide"
                                item-text="employee_code"
                                item-value="id"
                                required
                                ></v-select>--->
                                        <v-text-field placeholder="Enter External Guide" label="Internal Guide :" v-model="editedItem.intername_guide" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                                            :error-messages="error.editedItem.intername_guide ? 'Please enter internal guide' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter External Guide" label="External Guide :" v-model="editedItem.external_guide" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.editedItem.external_guide ? 'Please enter external guide' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Organization Name" label="Organization :" v-model="editedItem.org" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode > 47 && event.charCode < 58) || event.charCode == 32 "
                      :error-messages="error.editedItem.org ? 'Please enter organization' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Location" label="Location :" v-model="editedItem.location" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 "
                      :error-messages="error.editedItem.location ? 'Please enter location' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        Start Date :<br />
                                        <v-text-field type="date" placeholder="Start Date :" v-model="editedItem.start_date"
                                        :error-messages="error.editedItem.start_date ? 'Please select start date' : ''" />
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        End Date :<br />
                                        <v-text-field type="date" placeholder="End Date :" v-model="editedItem.end_date"
                                        :error-messages="error.editedItem.end_date ? 'Please select end date' : ''"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_edit= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="edit()">Save</v-btn> -->
                            <v-btn color="primary darken-1"  outlined text @click="dialog_edit= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="edit()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_delete" persistent max-width="290">
                    <v-card>
                        <v-card-title class="del-card-title">Record Delete</v-card-title>
                        <input type="hidden" v-model="deleteItem.delete_id" />
                        <v-card-text>
                            Are you sure you want Delete this Record?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
                            <v-btn color="red darken-1" text @click="deletedata()">Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
    </div>
</template>
<script>
    import axios from "axios";
    import StudentProfile from "./StudentProfile";
    import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
    import * as lang from "vuejs-datepicker/src/locale";
    import datetime from "vuejs-datetimepicker";
    export default {
        components: {
            StudentProfile,
            datetime,
            Datepicker,
        },
        data: () => ({
            show:false,
            savedata: {
                title: null,
                domain: null,
                description: null,
                role: null,
                technology: null,
                project_cat: null,
                intername_guide: null,
                external_guide: null,
                org: null,
                location: null,
                start_date: null,
                end_date: null,
            },
            data: 10 - 12 - 2010,
            dialog_add: false,
            dialog_edit: false,
            dialog_delete: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            project_List: "",
            pro_cat_list: "",
            internal_guide_list: "",
            headers: [
                {
                    text: "Sr. No.",
                    align: "left",
                    sortable: false,
                    value: "srno",
                },
                {
                    text: "Title",
                    align: "left",
                    sortable: false,
                    value: "title",
                },
                {
                    text: "Domain",
                    align: "left",
                    sortable: false,
                    value: "domain",
                },
                {
                    text: "Description",
                    align: "left",
                    sortable: false,
                    value: "description",
                },
                {
                    text: "Technology",
                    align: "left",
                    sortable: false,
                    value: "technology_used",
                },
                {
                    text: "Role",
                    align: "left",
                    sortable: false,
                    value: "role",
                },
                {
                    text: "Project Type",
                    align: "left",
                    sortable: false,
                    value: "projectcategory",
                },
                {
                    text: "Project Category",
                    align: "left",
                    sortable: false,
                    value: "projectcategory",
                },
                {
                    text: "Organization",
                    align: "left",
                    sortable: false,
                    value: "organization_name",
                },
                {
                    text: "Location",
                    align: "left",
                    sortable: false,
                    value: "location",
                },
                {
                    text: "Start Date",
                    align: "left",
                    sortable: false,
                    value: "start_date_show",
                },
                {
                    text: "End Date",
                    align: "left",
                    sortable: false,
                    value: "end_date_show",
                },
                 
                {
                    text: "Edit",
                    value: "edit",
                    sortable: false,
                },
                {
                    text: "Delete",
                    value: "delete",
                    sortable: false,
                },
            ],

            error:{
                savedata:{
                    title: false,
                    domain: false,
                    description: false,
                    role: false,
                    technology: false,
                    project_cat: false,
                    intername_guide: false,
                    external_guide: false,
                    org: false,
                    location: false,
                    start_date: false,
                    end_date: false,
                },
                editedItem:{
                    title: false,
                    domain: false,
                    description: false,
                    role: false,
                    technology: false,
                    project_cat: false,
                    intername_guide: false,
                    external_guide: false,
                    org: false,
                    location: false,
                    start_date: false,
                    end_date: false,
                },
            },

            editedItem: {
                title: null,
                domain: null,
                description: null,
                role: null,
                technology: null,
                project_cat: null,
                intername_guide: null,
                external_guide: null,
                org: null,
                location: null,
                start_date: null,
                end_date: null,
                id: "",
            },
            deleteItem: {
                delete_id: null,
            },
        }),
        mounted() {
            this.onLoad();
        },
        methods: {
            onLoad() {
                axios
                    .post("/LearnerData/learnerInternshipProject")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.project_List = res.data.learner_projects_list;
                            this.pro_cat_list = res.data.Proj_cat_List;
                            this.internal_guide_list = res.data.insternal_guide_List;
                        } else {
                            this.showSnackbar("#b71c1c", "No Data");
                           // window.console.log(res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        //window.console.log(error);
                    })
                    .finally(() => {
                        // 'use strict';
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
//    set1(value) {
     
//         this.prn = value.displayname.split(":")[0].trim();
//         this.isempselected = true;
      
//     },
            editItem(item) {
                //alert("ID "+item.end_date)
                this.dialog_edit = true;
                this.editedItem.title = item.title;
                this.editedItem.domain = item.domain;
                this.editedItem.description = item.description;
                this.editedItem.role = item.role;
                this.editedItem.technology = item.technology_used;
                this.editedItem.id = item.id;
                this.editedItem.project_cat = item.projectcategory_id;
                this.editedItem.intername_guide = item.internal_guide;
                this.editedItem.external_guide = item.external_guide;
                this.editedItem.org = item.organization_name;
                this.editedItem.location = item.location;
                this.editedItem.start_date = item.start_date;
                this.editedItem.end_date = item.end_date;
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            hasWhiteSpace(s) {
                var status = false;
                var reWhiteSpace = new RegExp(/^\s+$/);

                // Check for white space
                if (reWhiteSpace.test(s)) {
                    // alert("Please Check Your Fields For Spaces");
                    //   return true;
                    return /^\s/.test(s);
                }
                //   return false;
                return /^\s/.test(s);
            },
            save() {
                if (!this.savedata.title) {
                    this.showSnackbar("#b71c1c", "Enter Project Title...");
                    this.error.savedata.title = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.title)){
                        this.showSnackbar("#b71c1c", "Enter valid title...");
                        return;
                }
                 else if (this.savedata.domain == null) {
                    this.showSnackbar("#b71c1c", "Enter Domain..");
                    this.error.savedata.domain = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.domain)){
                        this.showSnackbar("#b71c1c", "Enter valid domain...");
                        return;
                }
                else if (this.savedata.description == null) {
                    this.showSnackbar("#b71c1c", "Enter Description..");
                    this.error.savedata.description = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.description)){
                        this.showSnackbar("#b71c1c", "Enter valid description...");
                        return;
                }
                 else if (this.savedata.role == null) {
                    this.showSnackbar("#b71c1c", "Enter Role..");
                    this.error.savedata.role = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.role)){
                        this.showSnackbar("#b71c1c", "Enter valid role...");
                        return;
                }
                 else if (this.savedata.technology == null) {
                    this.showSnackbar("#b71c1c", "Enter Technology Used..");
                    this.error.savedata.technology = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.technology)){
                        this.showSnackbar("#b71c1c", "Enter valid technology...");
                        return;
                }
                 else if (this.savedata.project_cat == null) {
                    this.showSnackbar("#b71c1c", "Select Project Category..");
                    this.error.savedata.project_cat = true;
                    return;
                } else if (this.savedata.intername_guide == null) {
                    this.showSnackbar("#b71c1c", "Select Internal Guide Name..");
                    this.error.savedata.intername_guide = true;
                    return;
                } else if (this.savedata.external_guide == null) {
                    this.showSnackbar("#b71c1c", "Select External Guide Name..");
                    this.error.savedata.external_guide = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.external_guide)){
                        this.showSnackbar("#b71c1c", "Enter valid external guide...");
                        return;
                }
                 else if (this.savedata.org == null) {
                    this.showSnackbar("#b71c1c", "Enter Organization Name ...");
                    this.error.savedata.org = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.org)){
                        this.showSnackbar("#b71c1c", "Enter valid organization name...");
                        return;
                }
                 else if (this.savedata.location == null) {
                    this.showSnackbar("#b71c1c", "Enter Location...");
                    this.error.savedata.location = true;
                    return;
                } else if(this.hasWhiteSpace(this.savedata.location)){
                        this.showSnackbar("#b71c1c", "Enter valid location...");
                        return;
                }
                 else if (this.savedata.start_date == null) {
                    this.showSnackbar("#b71c1c", "Select Start Date...");
                    this.error.savedata.start_date = true;
                    return;
                } else if (this.savedata.end_date == null) {
                    this.showSnackbar("#b71c1c", "Select End Date...");
                    this.error.savedata.end_date = true;
                    return;
                }else if(this.savedata.end_date < this.savedata.start_date){
                    this.showSnackbar("#b71c1c", "Valid Date Selection ...");
                    return;
                }
                 else {
                    this.dialog_add = false;
                    axios
                        .post("/LearnerData/saveInternshipProject", this.savedata)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.onLoad();
                                this.showSnackbar("#4caf50", "Project and Internship Details Add Successfully..."); // show snackbar on success
                                this.savedata.technology = null;
                                this.savedata.title = null;
                                this.savedata.role = null;
                                this.savedata.domain = null;
                                this.savedata.project_cat = null;
                                this.savedata.intername_guide = null;
                                this.savedata.external_guide = null;
                                this.savedata.org = null;
                                this.savedata.end_date = null;
                                this.savedata.description = null;
                                this.savedata.start_date = null;
                                this.savedata.location = null;
                            } else {
                                this.showSnackbar("#b71c1c", "Project Details Already Present!!!");
                            }
                        })
                        .catch((error) => {
                            //window.console.log(error);
                        });

                    this.close();
                }
            }, // end of Save()
            edit() {
                if (!this.editedItem.title) {
                    this.showSnackbar("#b71c1c", "Enter Project Title...");
                    this.error.editedItem.title = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.title)){
                        this.showSnackbar("#b71c1c", "Enter valid title...");
                        return;
                }
                 else if (!this.editedItem.domain) {
                    this.showSnackbar("#b71c1c", "Enter Domain..");
                    this.error.editedItem.domain = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.domain)){
                        this.showSnackbar("#b71c1c", "Enter valid domain...");
                        return;
                }else if (!this.editedItem.description) {
                    this.showSnackbar("#b71c1c", "Enter Description..");
                    this.error.editedItem.description = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.description)){
                        this.showSnackbar("#b71c1c", "Enter valid description...");
                        return;
                }
                 else if (!this.editedItem.role) {
                    this.showSnackbar("#b71c1c", "Enter Role..");
                    this.error.editedItem.role = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.role)){
                        this.showSnackbar("#b71c1c", "Enter valid role...");
                        return;
                }
                 else if (!this.editedItem.technology ) {
                    this.showSnackbar("#b71c1c", "Enter Technology Used..");
                    this.error.editedItem.technology = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.technology)){
                        this.showSnackbar("#b71c1c", "Enter valid technology...");
                        return;
                }
                 else if (!this.editedItem.project_cat) {
                    this.showSnackbar("#b71c1c", "Select Project Category..");
                    this.error.editedItem.project_cat = true;
                    return;
                } else if (!this.editedItem.intername_guide) {
                    this.showSnackbar("#b71c1c", "Select Internal Guide Name..");
                    this.error.editedItem.intername_guide = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.intername_guide)){
                        this.showSnackbar("#b71c1c", "Enter valid internal guide name...");
                        return;
                }
                 else if (!this.editedItem.external_guide) {
                    this.showSnackbar("#b71c1c", "Select External Guide Name..");
                    this.error.editedItem.external_guide = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.external_guide)){
                        this.showSnackbar("#b71c1c", "Enter valid external guide...");
                        return;
                }
                 else if (!this.editedItem.org) {
                    this.showSnackbar("#b71c1c", "Enter Organization Name ...");
                    this.error.editedItem.org = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.org)){
                        this.showSnackbar("#b71c1c", "Enter valid organization...");
                        return;
                } 
                else if (!this.editedItem.location) {
                    this.showSnackbar("#b71c1c", "Enter Location...");
                    this.error.editedItem.location = true;
                    return;
                }else if(this.hasWhiteSpace(this.editedItem.location)){
                        this.showSnackbar("#b71c1c", "Enter valid location...");
                        return;
                }
                 else if (!this.editedItem.start_date) {
                    this.showSnackbar("#b71c1c", "Select Start Date...");
                    this.error.editedItem.start_date = true;
                    return;
                } else if (!this.editedItem.end_date) {
                    this.showSnackbar("#b71c1c", "Select End Date...");
                    this.error.editedItem.end_date = true;
                    return;
                }else if(this.editedItem.end_date < this.editedItem.start_date){
                    this.showSnackbar("#b71c1c", "Valid Date Selection ...");
                    return;
                }
                 else {
                    this.dialog_edit = false;
                    axios
                        .post("/LearnerData/editInternshipProject", this.editedItem)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.onLoad();
                                this.showSnackbar("#4caf50", "Project and Internship Details Update Successfully..."); // show snackbar on success
                            } else {
                                this.showSnackbar("#b71c1c", "Something went wrong...");
                            }
                        })
                        .catch((error) => {
                            //window.console.log(error);
                        });

                    this.close();
                }
            },
            // end of edit()
            delId(item) {
                this.dialog_delete = true;
                this.deleteItem.delete_id = item.id;
            },
            deletedata() {
                this.dialog_delete = false;
                axios.post("/LearnerData/deleteInternshipProject", this.deleteItem).then((res) => {
                    if (res.data.msg == "200") {
                        if ((this.load = true)) {
                            this.onLoad();
                            this.showSnackbar("#4caf50", " Record Delete Successfully...");
                            th;
                        }
                    } else {
                        this.showSnackbar("#b71c1c", "Record Not Delete...");
                    }
                });
            },
        },
    };
</script>
<style scoped>
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
    .ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .logi{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
    .example {
        margin-top: 5px;
        background: #ffff;
        border-bottom: 1px solid rgb(161, 158, 158);
        width: 320px;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .alink {
        text-decoration: none;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
</style>
